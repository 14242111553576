<template>
  <div class="yunvideo">
    <!-- wrap -->
    <div class="wrap w clearfix mt100"
         v-if="$route.query.type == 1">
      <h5>{{title}}</h5>
      <p v-html="content">

      </p>
    </div>
    <div class="wrap w clearfix mt100"
         v-else>
      <h5>{{dataConten.title}}</h5>
      <div class="headTimeContent">
        <i>{{dataConten.create_time}}</i>
        <span>播放次数：{{dataConten.play_num}}</span>
      </div>
      <video v-if="dataConten"
             class="videos_medis"
             :src="dataConten.video_url"
             controls
             autoplay="autoplay"
             loop="loop"></video>
    </div>
  </div>
</template>

<script>
import { getCultureDetails } from "@/api/index.js";

export default {
  components: {
  },
  data () {
    return {
      content: '',
      title: '',
      dataConten: [],
    };
  },
  mounted () {

  },
  methods: {
    info () {
      let query = this.$route.query;
      getCultureDetails({
        'cultureId': query.id
      }).then(res => {
        if (res.code == '200') {
          this.content = res.data.content;
          this.title = res.data.title;
          this.dataConten = res.data;
        } else {
          // alert(res.message | '')
        }
      })
    }
  },
  watch: {
    $route: {
      handler () {
        this.info()
      },
      deep: true
    }
  },
  created () {
    this.info();
  },
};
</script>

<style scoped  lang="scss">
.headTimeContent {
  text-align: center;
  padding-top: 23px;
  color: #999;
  font-size: 14px;
  i {
    padding-right: 45px;
  }
}
.videos_medis {
  width: 100%;
  height: 675px;
  padding-top: 55px;
  object-fit: contain;
  &:focus {
    outline: none;
  }
}
// .banner{
// //   margin-bottom: 40px;
// // margin-bottom: 80px;
//   width: 100%;
//   height:500px;
//   background:url('../assets/images/banner4.jpg')no-repeat ;
//   background-size: 100% 100%;

//   .nav-left{
//     position: absolute;
//     top: 320px;
//     left:100px;
//     z-index: 100;
//   }

//   .nav-right{
//     position: absolute;
//     top: 320px;
//     right:100px;
//     z-index: 100;
//   }
// }

.wrap {
  // height: 1000px;
  // background: cornsilk;

  h5 {
    font-weight: 500;
    color: #565656;
    line-height: 33px;
    font-size: 24px;
    text-align: center;
    padding-top: 64px;
    box-sizing: border-box;
  }
  p {
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    padding-top: 12px;
    box-sizing: border-box;
  }
  img {
    padding: 25px 0;
    box-sizing: border-box;
  }
}

.mt100 {
  margin-bottom: 100px;
}

// .swiper-container{
//       height: 500px;
//       width: 100%;

//       .swiper-wrapper{
//         .swiper-slide{
//           width: 100%;
//           height: 100%;
//           // background-color: #42b983;
//           text-align: center;
//           line-height: 500px;
//           height:100%;width:100%; background: url(../assets/images/banner4.jpg)
//           no-repeat center top; background-size: 1920px 500px;
//     }
//   }
// }
</style>